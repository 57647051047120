<template>
  <div style="color: #161c2d;">
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain></HeaderMain>
    </header>

    <SectionHeading classname="text-center" text="What is an NFT"  isMargin="mb-3 mt-5"></SectionHeading>


    <!-- 顶部 -->
<!--    <section class="bg-half-170 d-table w-100 pb-0" style="padding-top: 100px;">-->
<!--      <div class="row justify-content-center">-->
<!--        <div class="col-12 text-center">-->
<!--          <div class="section-title mb-4 pb-2">-->
<!--            <h4 class="title mb-4">What Is Cloud Mining</h4>-->
<!--            &lt;!&ndash;            <p class="text-muted mx-auto mb-0">&ndash;&gt;-->
<!--            &lt;!&ndash;              If you could not find an answer to your questions,please contant our customer support&ndash;&gt;-->
<!--            &lt;!&ndash;            </p>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <section class="section pt-0" style="margin: 0 10px;">
      <div class="container" style="border-radius: 10px;">
        <h3>What is an NFT?</h3>
        <br/>
        <p>An NFT (non-fungible token) is a unique digital item stored on a blockchain. NFTs can represent almost anything, and serve as a digital record of ownership.</p>
        <br/>

        <strong style="font-size:22px;">
          Fungible vs. non-fungible
        </strong><br/><br>
        <p>Before we dive into NFTs, it’s important to understand the “non-fungible” part of “non-fungible token.” When an item is fungible, it means it’s interchangeable with another of the same item. A classic example is a $1 dollar bill: you could swap dollars with someone and you’d both still have $1.</p>
        <br/>
        <p>Non-fungible, on the other hand, means the item is totally unique, and therefore has its own unique value. For example, two cars of the same make and model might have different values based on how many miles are on the odometer, their accident records, or if it was previously owned by a celebrity. </p>
        <br/>

        <strong style="font-size:22px;">
          How do NFTs work?
        </strong><br/><br>
        <p>NFTs operate on blockchain technology. The blockchain is basically a large, digital, public record. The most popular blockchains are distributed across many nodes (read: people’s computers), which is why you’ll hear them described as “decentralized.” </p>
        <br/>
        <p>So instead of a central company-owned server, the blockchain is distributed across a peer-to-peer network. Not only does this ensure that the blockchain remains immutable, it also allows the node operators to earn money, instead of a single company. Because the blockchain records and preserves history, it is uniquely positioned to transform provable authenticity and digital ownership.</p>
        <br/>
        <p>When someone creates, transfers, buys, sells, or otherwise does something with an NFT, that all gets recorded on the blockchain. This is what enables authentication. </p>
        <br/>
        <p>This record serves as a permanent statement of authenticity that can be viewed or accessed by anyone. Today, when you buy a piece of art or a collector's item, it typically comes with a paper certificate of authenticity, which you must then keep track of forever. It is easily forgotten, lost or destroyed, creating a very fragile system for authenticity. Blockchain’s offer a simple and more secure solution to this long standing issue of proving authenticity.</p>
        <br/>
        <p>Let’s say you want to buy a piece of artwork from Tyler Hobbs. With NFTs, you can see the entire history of that piece, all the past owners, every sale, all the way back to Hobbs’ original creation of the piece. Without NFTs, you wouldn't know if you were buying the real piece or just a really good fake.</p>
        <br/>

        <strong style="font-size:22px;">
          The impact of NFT technology
        </strong><br/><br>
        <p>Blockchain technology is revolutionary for digital items. With NFTs, digital items can be provably scarce, openly transferable, and have authenticated ownership. But you might be thinking…so what?</p>
        <br/>
        <p>For creators, these new attributes are incredibly powerful. Instead of distributing their artwork, music, or other creations on platforms that are traditionally hard to monetize, they’re able to sell unique and authenticated items on a blockchain-based marketplace. In addition to the initial sales, NFT creators may receive set creator earnings on secondary sales. For example, a developer could make an in-game skin that can be used across a variety of games and has established authenticity and ownership, and that developer may earn money other times that skin is bought or sold.</p>
        <br/>
        <p>This technology is revolutionary for collectors, too. Imagine you’re about to buy a concert ticket online— with NFTs, you can trust its authenticity, because of the undisputed blockchain history, instead of relying on the reseller’s word. </p>
        <br/>

        <strong style="font-size:22px;">
          What are NFTs used for?
        </strong><br/><br>
        <p>An NFT can represent anything, but let’s explore some of the ways NFTs are being used today, and potential implementations for the future.</p>
        <br/>
        <p>Art NFTs</p>
        <br/>
        <p>An art NFT is a type of NFT that represents a piece of digital art, such as a drawing, painting, or piece of digital artwork. Each art NFT is unique and traceable to the original creator of the NFT, and that connection to the creator may be valuable as well. Art NFTs are a new form of digital art that can be collected and sold, similar to physical artwork. Art NFTs can also have additional utility (for example, owning the NFT may also give you commercial rights to use the underlying artwork).</p>
        <br/>
        <p>Artists are creating incredible and novel pieces with NFTs. Damien Hirst used NFTs in his collection “The Currency”, in which he created digital versions of 10,000 unique physical paintings. Collectors had one year to decide if they wanted the digital or the physical version of the painting— whichever version they did not choose would be destroyed.</p>
        <br/>

        <strong style="font-size:22px;">
          Profile picture NFTs (PFPs)
        </strong><br/><br>
        <p>A profile picture, or PFP, NFT is any NFT that's primary purpose is use as a social media profile picture, or avatar. These NFTs are digital items that are authenticated on a blockchain network, making them verifiably unique. Some PFP NFTs also grant access to designated online communities. </p>
        <br/>
        <p>Profile picture NFTs come in various forms, ranging from static images to animated or interactive designs. They may be based on characters, take the form of animals, look like humans, or be abstract.</p>
        <br/>
        <p>These are probably the projects you’ve heard the most about: Bored Ape Yacht Club (BAYC), Doodles, World of Women (WoW), and more. For many people on the internet, these PFPs actually become their online identity. Not only do they identify with the group, they strongly identify with their avatar. PFP projects are also centered around holder benefits (like BAYC’s famous yacht party) and community (like WoW, which donates a portion of their creator earnings to women-centric charities). </p>
        <br/>


        <strong style="font-size:22px;">
          Music NFTs
        </strong><br/><br>
        <p>A music NFT is any NFT that represents ownership of music or an experience tied to music, like a live concert event or a virtual fan meet-and-greet. Music NFTs are created by minting a unique token on a blockchain, which can then be collected by music fans.</p>
        <br/>
        <p>Music NFTs can be created by new artists and musicians with large fanbases. These NFTs can include anything from a recording to a composition to ticketing for a music experience. The content of each music NFT varies based on the creator and the work they decide to create.</p>
        <br/>
        <p>Any artist can create a single song or audio file as an NFT. For example, omgkirby partnered with Channel Tres to create a collection of 5,550 unique songs, each with its own BPM and key. Each song comes with vocals and production by Channel Tres.</p>
        <br/>

        <strong style="font-size:22px;">
          Trading Card NFTs and Digital Collectibles
        </strong><br/><br>
        <p>NFTs bring some extra oomph to your traditional collectibles. Instead of a physical basketball trading card that sits in a binder under your bed, you can collect dynamic NFTs from the NBA’s collection “The Association,” where each card changes based on that player and team’s performance.</p>
        <br/>

        <strong style="font-size:22px;">
          Domain name NFTs
        </strong><br/><br>
        <p>Think of a domain name NFT like an easy-to-remember shortcut to your wallet. Like other types of NFTs, a domain name NFT is stored on the blockchain. In web2, websites are accessed through DNS (Domain Name System) servers. DNS servers translate website addresses into IP addresses. Similarly, in web3, wallets can be accessed through domain names. These domain names translate into wallet addresses. </p>
        <br/>
        <p>With growing concern about the ownership of personal data and a desire to avoid third parties like domain registrars, domain name NFTs have become a potential answer to the question of how to retain more control of your data. Domain name NFTs allow users to own and control the data that lives on their domain in contrast to a hosted web2 domain name.</p>
        <br/>
        <p>NFT domains can have extensions like .eth, .polygon, .nft, .crypto, .bitcoin, .x, and .blockchain.</p>
        <br/>
        <p>Naming standards like Ethereum Naming Service (ENS) and Bonfida (the equivalent naming service for Solana) have emerged to streamline naming for wallets, websites, and other blockchain applications. These help make using the blockchain more user-friendly, with human-readable names and built-in verification.</p>
        <br/>

        <strong style="font-size:22px;">
          Gaming NFTs
        </strong><br/><br>
        <p>A gaming NFT is an NFT associated with any digital item from the realm of online gaming and the metaverse: in-game items, characters, skins, customizations, maps, modes, tickets, collectibles–any digital creation that one would use in a gaming environment. It is a unique, blockchain-based item representing a specific item or element within a game.</p>
        <br/>
        <p>For example, if you unlocked a tool while playing an NFT-supported game, you could mint that in-game item and take ownership of it. This ownership is verifiable, so your tool can typically be sold, collected, and used just like a real-world item.</p>
        <br/>
        <p>Today, purchases or acquisitions made within a game go away the instant the game does. Your “ownership” is contingent on the game developers maintaining the game. Ostensibly, they could choose to take an item away or decommission items you own, causing you to lose them. Additionally, gamers are unable to resell items outside of the closed ecosystem of a given game. With gaming NFTs, digital items can be given provable ownership. Digital items and memorabilia can also have provable scarcity, which gives them a similar supply and demand dynamic to their real-world counterparts.</p>
        <br/>
        <p>Another benefit of gaming NFTs is the potential of a new era of “interoperability,” or how things from one game or gaming universe can be used in another. Today, a game and all its items are siloed, which means they exist only within that game. You can’t use a weapon from Halo in the Call of Duty universe or play FIFA as Mario. But if more games leverage blockchain technology as an interoperable ledger for proof of ownership and standardize their systems for how to render and display items, NFTs could eventually allow in-game items from one game to be operable in more games.</p>
        <br/>
        <p>Some of this work is dependent on blockchain technology, while some of it is dependent on how the games of the future are built. Organizations like Metaverse Standards are working to provide cooperation between organizations and companies to foster the development of an open and inclusive metaverse.</p>
        <br/>
        <p>The sheer quantity of digital items created by both game developers and the billions of fans that play their games dwarfs many other areas of digital items, making gaming NFTs a growing application of blockchain technology.</p>
        <br/>
        <p>Gaming NFTs are revolutionary because of their utility, ownership, and interoperability. This category is still relatively nascent however many gamers are excited by the possibilities of in-game items being usable across different games and not under the control of a single game studio. Aurory Project is a gaming studio that leverages the Solana blockchain to streamline in-game item ownership.</p>
        <br/>

        <strong style="font-size:22px;">
          Future applications
        </strong><br/><br>
        <p>New applications for the blockchain are constantly emerging. New use cases include identity verification, intellectual property, and storage solutions, like Courtyard, which holds physical inventory and enables you to safely hold, sell, or claim your item you’re ready to redeem. The possibilities for future applications are endless!</p>
        <br/>



      </div>
    </section>



    <Footer classname="bg-black on-dark"></Footer>
    <!--    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top"-->
    <!--       v-scroll-to="'#topnav'">-->
    <!--      <arrow-up-icon class="icons"></arrow-up-icon>-->
    <!--    </a>-->
    <!-- Back to top -->
  </div>
</template>
<script>/**
 * Index-1
 */
import storage from "@/storage";

export default {
  name: "WhatIsAnNFT",
  metaInfo: {
    title: 'SOHASH',
    link: [
      { rel: 'canonical', href: 'https://sohash.com/what-is-an-nft' }
    ]
  },
  data() {
    return {
      data: [],
      login: false
    }
  },
  components: {

  },
  created() {
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true;
    }
  }
};
</script>


